.toggle-container {
    position: relative;
    width: 60px;
    height: 26px;
    background-color: #3937d8;
    cursor: pointer;
    user-select: none;
    border-radius: 20px;
    padding: 1px;
}
.toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 25px;/* 
    font-weight: bold;
    font-size: 14px;
    line-height: 16px; */
    height: 24px;
    cursor: pointer;
    color: #fff;
    background-color: #78df68;
    box-shadow: 0 2px 4px rgb(0, 0, 0, 0.25);
    padding: 8px 12px;
    border-radius: 20px;
    position: absolute;
    transition: all 0.2s ease;
    left: 33px;
}

.disable {
    background-color: #78df68;
    left: 2px;
}




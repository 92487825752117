@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Jost:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Signika+Negative:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
    .bodybg{
     background: #A93226;
     color:#ffffff;  
    }
    /* .bgcolorlite{
      background: #FFCCBC;
      color:#000000;
    } */
    .bgcolorlite{
      @apply text-black bg-red-300;
    }
    
    .bggray{
      @apply bg-gray-300 text-black;
    }
    .wrapper {
      @apply max-w-7xl lg:mx-auto p-4 md:px-10 xl:px-2 w-full;
    }

    .wrapper-weaves {
        @apply lg:mx-auto p-2 md:px-7 w-full;
      }
      .hero-carousel {
        @apply relative sm:px-10 py-5 sm:pt-20 pb-5 max-w-[560px] h-[700px] w-full bg-[#F2F4F7] rounded-[30px] sm:mx-auto;
      }
}